import React, {useState} from 'react';
import './Controls.css';

function Random(props) {
    const {play} = props;

    function trackAndPlayRandom() {
        window.gtag('event', 'playRandom');
        play();
    }

    return (
        <div onClick={trackAndPlayRandom}>> Mne je jedno...</div>
    );
}

function EndlessShuffle(props) {
    const {start, stop} = props;
    const [endlessShuffle, setEndlessShuffle] = useState(false);

    function shuffleOn() {
        window.gtag('event', 'shuffleOn');
        start();
        setEndlessShuffle(true);
    }

    function shuffleOff() {
        window.gtag('event', 'shuffleOff');
        stop();
        setEndlessShuffle(false);
    }

    if (!endlessShuffle) {
        return (
            <div onClick={shuffleOn}>> Choj ich jebat!</div>
        );
    }

    return (
        <div onClick={shuffleOff}>> Budz ticho!</div>
    )
}

function DelayedRun() {
    const [delayedRun, setDelayedRun] = useState(-1);

    function delayedRunOn() {
        setDelayedRun(60);
    }

    function delayedRunOff() {
        setDelayedRun(-1);
    }

    if (delayedRun <= 0) {
        return (
            <div onClick={delayedRunOn}>> No sak pockaj</div>
        )
    }

    return (
        <div><span onClick={delayedRunOff}>> Nik ta!</span>
            <div>- <span><input className="delayed-run-val" value={delayedRun} type="number" maxLength={3}/></span> +</div>
        </div>
    );

}

export function Controls(props) {
    const {player} = props;

    return (
        <div className="controls">
            <Random play={player.playRandomTrack}/>
            <EndlessShuffle start={player.startEndlessShuffle} stop={player.stopEndlessShuffle}/>
        </div>
    )
}
