import * as cn from "classnames";
import React from "react";
import {Col, Container, Row} from "react-grid-system";
import './TrackList.css';

function Tile(props) {
    const {id, title, asset, ref, onPlayEnded, play, className} = props;

    function trackAndPlay() {
        window.gtag('event', 'play', { 'event_category': 'manual', 'event_label': title});
        play();
    }

    return (
        <Col xl={2} lg={3} md={4} sm={4} xs={4} key={id}>
            <div className={cn('tile', className)} onClick={trackAndPlay}>
                <span className="tile-text">{title}</span>
                <audio src={asset} autoPlay={false} onEnded={onPlayEnded} ref={ref} preload="none"/>
            </div>
        </Col>
    )
}

export function TrackList(props) {
    const {tracks} = props;

    return (
        <Container className="track-list">
            <Row>
                {tracks.map(Tile)}
            </Row>
        </Container>
    );
}
