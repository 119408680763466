import React, {useState} from 'react';
import {setConfiguration} from 'react-grid-system';
import './App.css';
import audio from "./audio";
import {Controls} from "./components/Controls";
import {SearchBar} from "./components/SearchBar";
import {TrackList} from "./components/TrackList";

setConfiguration({
    gutterWidth: 10,
    gridColumns: 24,
    breakpoints: [576, 768, 992, 1200],
    containerWidths: [540, 750, 960, 1140]
});
const player = audio.initializePlayer(audio.tracks);

function App() {
    const [playlist, setPlaylist] = useState(player.playlist);

    function searchPlaylist(value) {
        if (!value || value === '') {
            setPlaylist(player.playlist);
        } else {
            const newPlaylist = player.playlist.filter(t => t.title.toLocaleLowerCase().search(value) >= 0);
            setPlaylist(newPlaylist);
        }
    }

    return (
        <div className="app">
            <Controls player={player}/>
            <SearchBar searchPlaylist={searchPlaylist}/>
            <TrackList tracks={playlist}/>
        </div>
    );
}

export default App;
