import React, {useState} from 'react';
import './SearchBar.css';

let timerHandle;

export function SearchBar(props) {
    const {searchPlaylist} = props;
    // search value '' ==> input will be controlled component
    const [search, setSearch] = useState('');

    function onSearch(event) {
        const newValue = event.target.value;
        setSearch(newValue);
        searchPlaylist(newValue);

        if (timerHandle) {
            clearTimeout(timerHandle);
            timerHandle = undefined;
        }

        timerHandle = setTimeout(() => {
            // log search term after some delay
            window.gtag('event', 'search', { search_term: newValue })
        }, 1500)
    }

    return (
        <div className="search-bar">
            <input className="search-input"
                   type="text" placeholder="No co tam dam?!"
                   value={search}
                   onChange={onSearch}
            />
        </div>
    )
}