import React from "react";

let playlist = {};

/**
 * Currently selected audio track
 */
let currentTrack;

/**
 * Indicates whether currently selected audio should be repeated.
 *
 * @type {boolean}
 */
let repeat = false;

/**
 * Indicates whether repeat should randomize tracks.
 *
 * @type {boolean}
 */
let shuffle = false;

//
//
//

function initializePlaylist(tracks) {
    playlist = tracks
        .filter(t => t.title && t.asset)
        .map((t, idx) => ({
            id: idx,
            ref: React.createRef(),
            onPlayEnded,
            play: () => selectAndPlayTrack(idx),
            ...t
        }))
        .reduce((acc, t) => {
            acc[t.id] = t;
            return acc;
        }, {});
}

function getPlaylist() {
    return Object.values(playlist);
}

function selectTrack(id) {
    currentTrack = playlist[id].ref.current;
}

function selectRandomTrack() {
    const tracks = Object.getOwnPropertyNames(playlist);
    const randomPick = Math.floor(Math.random() * tracks.length);

    currentTrack = playlist[randomPick].ref.current;
}

function stopCurrentTrack() {
    if (!currentTrack) {
        return;
    }

    currentTrack.pause();
}

function selectAndPlayTrack(id) {
    // stop whatever is playing now
    stopCurrentTrack();
    selectTrack(id);
    playCurrentTrack();
}


function onPlayEnded() {
    if (!repeat) {
        currentTrack = undefined;
        return;
    }

    if (shuffle) {
        selectRandomTrack();
    }

    playCurrentTrack();
}

function playCurrentTrack() {
    if (!currentTrack) {
        return;
    }

    currentTrack.load();
    currentTrack.play();
}

function playRandomTrack() {
    stopCurrentTrack();
    selectRandomTrack();
    playCurrentTrack();
}


function startEndlessShuffle() {
    repeat = true;
    shuffle = true;

    playRandomTrack();
}


function stopEndlessShuffle() {
    repeat = false;
    shuffle = false;

    stopCurrentTrack();
}

//
// Public interface
//

/**
 * Initialize audio player with list of tracks. Each track is represented by an object that must have 'title' and 'asset'
 * properties.
 *
 * @param tracks list of tracks; track = { title: ..., asset: ...}
 */
export function initializePlayer(tracks) {
    initializePlaylist(tracks);

    return {
        playlist: getPlaylist(),
        /**
         * Plays a random track
         */
        playRandomTrack,
        /**
         * Endlessly play random tracks.
         */
        startEndlessShuffle,
        /**
         * Stop the endless shuffle.
         */
        stopEndlessShuffle
    }
}

