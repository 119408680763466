import {initializePlayer} from "./player";

const tracks = [];

function transformWord(w) {
    return (w.charAt(0).toUpperCase() + w.substr(1))
        .replace(/\$c/g, ',')
        .replace(/\$qe/g, '?!')
        .replace(/\$q/g, '?')
        .replace(/\$e/g, '!')
        .replace(/\$E/g, '!!!');
}

/**
 * Transforms asset path name to track title; there is a simple convention:
 *
 * - Track name is the title
 * - Words are delimited by '_'
 * - Words will be capitalized
 * - If multiple tracks should have same title, then have last word a number (sequence or whatever) - it will be stripped
 * - Character sequences will be replaced by punctuation as follows:
 *   - $c  = ,
 *   - $q  = ?
 *   - $e  = !
 *   - $qe = ?!
 *   - $E  = !!!
 *
 * @param pathname asset pathname
 * @returns {string} title string
 */
function filenameToTitle(pathname) {
    const split = pathname.split('/');
    const filename = split[split.length - 1].split('.')[0];
    const words = filename.split('_').map(transformWord);

    // get rid of last 'word' if it is a number
    words[words.length-1] = words[words.length-1].replace(/[0-9]/g, '');

    return words.join(' ');
}

function importAll(r, meta) {
    r.keys().forEach(key => tracks.push({
        title: filenameToTitle(r(key)),
        asset: r(key),
        ...meta
    }));
}

importAll(require.context('./cibula/', false, /\.mp3$/), {className: 'cibula'});
importAll(require.context('./mravce/', false, /\.mp3$/), {className: 'mravce'});
importAll(require.context('./dvere/', false, /\.mp3$/), {className: 'dvere'});
importAll(require.context('./voda/', false, /\.mp3$/), {className: 'voda'});
importAll(require.context('./sol_neni/', false, /\.mp3$/), {className: 'sol'});
importAll(require.context('./ziacka/', false, /\.mp3$/), {className: 'ziacka'});
importAll(require.context('./250_razy/', false, /\.mp3$/), {className: 'nozom'});
importAll(require.context('./sa_dosmejes/', false, /\.mp3$/), {className: 'hahaha'});
importAll(require.context('./televizor/', false, /\.mp3$/), {className: 'televizor'});
importAll(require.context('./500_korun/', false, /\.mp3$/), {className: 'peniaze'});
importAll(require.context('./ine/', false, /\.mp3$/), {className: 'mix'});
importAll(require.context('./nos/', false, /\.mp3$/), {className: 'nos'});
importAll(require.context('./zavinac/', false, /\.mp3$/), {className: 'zavinac'});
importAll(require.context('./papuca/', false, /\.mp3$/), {className: 'papuca'});

tracks.sort((a, b) => a.title.localeCompare(b.title));

export default {
    tracks,
    initializePlayer
}
